import React from "react";
import styled from "styled-components";
import withContainer from "../hoc/withContainer";
import {API} from "../data";

const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
  flex-wrap: wrap;
`

const Title = styled.h2`
  font-size: 24px;
  line-height: 1.5em;
`

const Content = styled.p`
  font-size: 12px;
  line-height: 1.5em;
`

const TitleContent = styled.div`
  margin-right: 25px;
  max-width: 300px; 
`

const TitleAudio = (props) => {

    return <SideBySide>
        <TitleContent>
            <Title>{props.title}</Title>
            <Content>{props.text}</Content>
        </TitleContent>
        <audio src={`${API.baseUrl}/${props.audio}`} controls="controls"/>
    </SideBySide>

}

export default withContainer(TitleAudio);
