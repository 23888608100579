import React from "react";
import withContainer from "../hoc/withContainer";
import withImageSize from "../hoc/withImageSize";
import styled from 'styled-components';

const SideBySide = styled.div`
  margin-bottom: 15px;
`

const Title = styled.h2`
  font-size: 24px;
  line-height: 1.5em;
  text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
  }
`

const CenterMobile = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

  @media (min-width: 1024px) {
    display: block;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 1.5em;
  text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
  }
`

const FotoText = (props) => {

    const Image = withImageSize(props.foto.path, 200, 200);

    return <SideBySide>
        <div className="container">
            <div className="row">
                <div className="col-md-8 col-sm-12">
                  <CenterMobile>
                    <Image/>
                  </CenterMobile>
                </div>
                <div className="col-md-4 col-sm-12">
                    <Title>{props.name}</Title>
                    <Content>{props.content}</Content>
                </div>
            </div>
        </div>
    </SideBySide>
}

export default withContainer(FotoText);
