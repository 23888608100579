import React from "react";
import styled from "styled-components";
import withContainer from "../hoc/withContainer";

const Styling = styled.p`
  font-size: ${(props) => (props.fontsize) ? props.fontsize + 'px' : '18px'}
  text-align: ${(props) => (props.position) ? props.position : 'left'};
`
const LongText = (props) => <Styling fontsize={props.fontsize} position={props.position}>{props.entry}</Styling>

export default withContainer(LongText);
