import React from "react";
import styled from "styled-components";
import Footer from "../components/footer";
import useFetch from "../hooks/useFetch";
import {API} from "../data";
import Navigator from "../components/navigator";


const White = styled.div`
    background-color: white;
    min-height: calc(100vh - 125px);
    padding-right: 25px;
    padding-left: 25px;
`

const LightBlue = styled.div`
  background-color: #e8f6ff;
`

const ContentWrapper = () => {
    const [websiteContent] = useFetch(`${API.baseUrl}/cockpit/api/singletons/get/Navigation?token=${API.key}`, [])

    return (
        <LightBlue>
            <div className="container">
                <White>
                    <Navigator entries={websiteContent.Item}/>
                </White>
                <Footer/>
            </div>


        </LightBlue>
    )
}

export default ContentWrapper
