import {API} from "../data";
import React from "react";
import styled from "styled-components";

const BoxShadow = styled.img`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 200px;
  height: 200px;
`

const withImageSize = (path, width, height) => {
    return (props) => {
        const resizeImage = () => {
            return `${API.baseUrl}/cockpit/api/cockpit/image?token=${API.key}&src=${API.baseUrl}${path}&w=${width}&h=${height}&o=1`
        }

        return <BoxShadow src={resizeImage(path)} alt="" {...props}/>
    }
}

export default withImageSize;
