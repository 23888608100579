
const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
const transformDateFormat = (date) => (new Date(date).toLocaleString('de-DE', options))
const compareDateWithToday = (date) => date > new Date().getTime()

const splitArrayByDate = (acc, current) => {
    const date = transformDateFormat(current.Datum)

    if (compareDateWithToday(current.Datum)) {
        return [[...acc[0], {...current, Datum: date}], acc[1]]
    }
    return [acc[0], [{...current, Datum: date}, ...acc[1]]]
}

const getTime = (time) => new Date(time).getTime()
const inDescOrder = (a, b) => a.Datum - b.Datum
const setAsUCT = (item) => ({...item, Datum: getTime(item.Datum)})

const setContent = (content) => content
    .map(setAsUCT)
    .sort(inDescOrder)
    .reduce(splitArrayByDate, [[], []])

export default setContent;
