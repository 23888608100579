import React from "react";
import LargeTitle from "../cockpitComponents/LargeTitle";
import SubTitle from "../cockpitComponents/SubTitle";
import LongText from "../cockpitComponents/LongText";
import Gallery from "../cockpitComponents/Gallery";
import Divider from "../cockpitComponents/Divider";
import Konzerte from "../cockpitComponents/Konzerte";
import FotoText from "../cockpitComponents/FotoText";
import TitleAudio from "../cockpitComponents/TitleAudio";
import Kontakt from "../cockpitComponents/Form";
import Presse from "../cockpitComponents/Presse";
import Video from "../cockpitComponents/Video";

const components = {
  LargeTitle: LargeTitle,
  SubTitle: SubTitle,
  LongText: LongText,
  Gallery: Gallery,
  Divider: Divider,
  Konzerte: Konzerte,
  FotoText: FotoText,
  TitleAudio: TitleAudio,
  Kontakt: Kontakt,
  Presse: Presse,
  Video: Video,
};

const ContentBuilder = ({ content = [] }) => {
  const layout = () => {
    return content.map((item) => {
      const Component = components[item.component];
      const props = item.settings;
      if (props.key) {
      } else {
        console.log(item.component, props.key);
      }
      return <Component key={props.key} {...props} />;
    });
  };

  return <>{layout()}</>;
};

export default ContentBuilder;
