import React from 'react'
import styled from 'styled-components'
import {device} from "../util/global";

const MobNav = styled.div`
   display: flex;
   justify-content: space-evenly;
   flex-direction: column;
   height: 25px;
   width: 25px;
   
   @media ${device.tablet} {  
    display: none
  }
`

const Line = styled.div`
  height: 2.5px;
  width: 100%;
  background-color: black;
`


const MobileNavigation = ({state, fn}) => {
    const [localState, setLocalState] = React.useState(state)
    return (
        <MobNav onClick={() => {
            fn(!localState);
            setLocalState(!localState);
        }}>
            <Line/>
            <Line/>
            <Line/>
        </MobNav>
    )
}

export default MobileNavigation;
