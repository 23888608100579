import React from "react";
import {useClassWidth} from "../hooks/useClassWidth";


const withContainer = (Template) => {
    return (props) =>
        (<div className="container">
                <div className="row">
                    <div className={useClassWidth(props.width)}>
                        <Template {...props}/>
                    </div>
                </div>
            </div>
        )
}

export default withContainer;
