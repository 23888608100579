import React from "react";
import styled from "styled-components";
import withContainer from "../hoc/withContainer";

const Styling = styled.h2`
  font-size: ${(props) => (props.fontsize) ? props.fontsize + 'px' : '21px'}
  text-align: ${(props) => (props.position) ? props.position : 'center'};
`
const SubTitle = (props) => <Styling fontsize={props.fontsize} position={props.position}>{props.entry}</Styling>

export default withContainer(SubTitle);
