import React from 'react';
import useFetch from "../hooks/useFetch";
import {API} from "../data";
import ContentBuilder from "./contentBuilder";


const Coordinator = ({match}) => {

    let slug = match.url
    if (match.url === '/') {
        slug = '/startseite'
    }

    const [websiteContent] = useFetch(`${API.baseUrl}/cockpit/api/singletons/get${slug}?token=${API.key}`)
    const content = websiteContent.layout


    return <ContentBuilder content={content}/>
}

export default Coordinator;
