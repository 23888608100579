import React from "react";
import withContainer from "../hoc/withContainer";
import styled from 'styled-components'

const Block = styled.div`
  margin-bottom: 25px;
`

const Presse = (props) => {


    return <Block>
        <div className="row">
            <div className="col-md-3">
                <b>{props.journal}</b>
            </div>
            <div className="col-md-9">
                {props.Eintrag}
            </div>
        </div>
    </Block>
}

export default withContainer(Presse);
