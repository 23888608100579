import React from "react";
import Slider from "react-slick";
import {API} from "../data";
import {DEVICE_SIZE, smallerThenDeviceSize} from "../util/global";

const AutoPlay = ({entry}) => {

    const resizeImageResponsive = (path) => {
        const deviceWidth = window.innerWidth - 50 - 30 ;
        if (smallerThenDeviceSize(DEVICE_SIZE.tablet))
            return `${API.baseUrl}/cockpit/api/cockpit/image?token=${API.key}&src=${API.baseUrl}${path}&w=${deviceWidth}&o=1`

        return `${API.baseUrl}/cockpit/api/cockpit/image?token=${API.key}&src=${API.baseUrl}${path}&w=700&h=500&o=1`
    }

    const buildSlider = () => entry.map(item => <div key={item.path}><img src={resizeImageResponsive(item.path)} alt=""/></div>)

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        lazyLoad: false,
        cssEase: "linear",
        centerMode: true,
        centerPadding: 0,

    }

    return (
        <Slider {...settings}>
            {buildSlider()}
        </Slider>
    );
}

export default AutoPlay;
