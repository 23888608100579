import React from "react";
import withContainer from "../hoc/withContainer";
import styled from "styled-components";
import { API } from "../data";

const CenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Padding = styled.div`
  padding-top: 10px;
`;

const Title = styled.p`
  font-size: 12px;
  line-height: 1.5em;
`;

const VideoPlat = styled.video`
  object-fit: fill;
  max-width: 60vw;
`;

export const Video = ({ video, description }) => {
  return (
    <Padding>
      <CenterWrap>
        <VideoPlat controls>
          <source src={`${API.baseUrl}/${video}`} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoPlat>
        {description && <Title>{description}</Title>}
      </CenterWrap>
    </Padding>
  );
};

export default withContainer(Video);
