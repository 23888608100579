import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {API} from "../data";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}));

const Kontakt = () => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name: '',
        email: '',
        title: '',
        age: '',
        textfield: '',
    });

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value});
    };

    const sendData = () => {
        fetch(API.baseUrl + '/cockpit/api/forms/submit/Kontakt?token=' + API.key, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                form: values
            })
        })
            .then(entry => entry.json())
            .then(() => window.location.href = window.location.origin);
    }

    return (
        <form className={classes.container} noValidate autoComplete="off">
            <TextField
                id="standard-name"
                label="Ihr Name"
                className={classes.textField}
                value={values.name}
                onChange={handleChange('name')}
                margin="normal"
            />
            <TextField
                id="standard-name"
                label="Ihre E-Mail-Adresse"
                className={classes.textField}
                value={values.email}
                onChange={handleChange('email')}
                margin="normal"
            />
            <TextField
                id="standard-name"
                label="Betreff"
                className={classes.textField}
                value={values.title}
                onChange={handleChange('title')}
                margin="normal"
            />
            <TextField
                id="standard-multiline-flexible"
                label="Ihre Nachricht"
                multiline
                rowsMax="4"
                value={values.textfield}
                onChange={handleChange('textfield')}
                className={classes.textField}
                margin="normal"
            />
            <Button variant="contained" color="primary" className={classes.button} onClick={() => sendData()}>
                Send
            </Button>
        </form>
    );
}

export default Kontakt;
