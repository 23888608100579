import React from "react";
import AutoPlay from "../components/slickSlider";
import styled from 'styled-components'


const Smaller = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

const Gallery = (props) => {
    const {entry} = props

    return <Smaller><AutoPlay entry={entry}/></Smaller>

}

export default Gallery;
