import React from "react";
import styled from 'styled-components';


const Padding = styled.div`
  padding-top: 10px;
`


const Divider = () => {

    return <Padding/>
}

export default Divider;
