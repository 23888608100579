import React from "react";
import styled from 'styled-components'
import withContainer from "../hoc/withContainer";

const Styling = styled.h1`
  font-size: ${(props) => (props.fontsize) ? props.fontsize + 'px' : '21px'}
  text-align: ${(props) => (props.position) ? props.position : 'center'};
  font-weight: bold;
`

const LargeTitle = (props) => <Styling fontsize={props.fontsize} position={props.position}>{props.entry}</Styling>


export default withContainer(LargeTitle);
