import React, {useState} from "react";
import {API} from "../data";
import useFetch from "../hooks/useFetch";
import styled from 'styled-components';
import setContent from "../hooks/setTime";
import {device} from "../util/global";

const OverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`

const ItemWrapper = styled.div`
  min-width: 80vw;
  
  @media ${device.tablet} {  
    min-width: 450px;
  }
  
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 0 1px 2px #80808075;
  margin: 5px;
  padding: 5px;
`

const Item = styled.div`
  text-align: center;
  line-height: 1.6em;
  font-size: 0.75rem;
  max-width: 10rem;
  margin-bottom: 0.5rem;
`

const InlineWithList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const InlineTitle = styled.h5`
  margin: 0 0 25px;
`

// const InlineIcon = styled(FontAwesomeIcon)`
//   font-size: 1.25rem;
// `

// const Turner = styled.div`
//   transform: rotate(${(props) => (props.initial) ? '' : '135deg'});
//   transition: all 350ms ease-in-out;
//   margin-right: 5rem;
//   color: green;
//   line-height: 0;
// `

const WrapperDivider = styled.div`
  margin-bottom: 25px;
`


const concertEntry = item => <ItemWrapper key={item.key}>
    <Item>{item.Datum}</Item>
    <Item>{item.Anlass}</Item>
</ItemWrapper>

const showEntries = (entries) => {
    return <>
        <ItemWrapper>
            <b><Item>Datum</Item></b>
        </ItemWrapper>
        {entries.map(concertEntry)}
    </>
}

const EntryList = ({entries, title}) => {
    const [show, setShow] = useState(true)

    return <>
        <InlineWithList onClick={() => setShow(!show)}>
            <InlineTitle>{title}</InlineTitle>
        </InlineWithList>
        {show && showEntries(entries)}
        <WrapperDivider/>
    </>
}

const Konzerte = () => {

    const [content] = useFetch(`${API.baseUrl}/cockpit/api/collections/get/Konzert?token=${API.key}`, {entries: []});
    const [upcoming, passed] = setContent(content.entries)


    return <OverWrapper>
        <EntryList entries={upcoming} title={'Unsere nächsten Konzerte'}/>
        <EntryList entries={passed} title={'Unsere vorherigen Konzerte'}/>
    </OverWrapper>
}

export default Konzerte;
